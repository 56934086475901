import { ProjectFormModel } from './projectFormModel';
import { Location, Project } from '../../api/sentinel';

const dtoToForm = (projectId: string, data: Project): ProjectFormModel => {
    const {
        active,
        businessId,
        clientName,
        commentary,
        endDate,
        locations,
        engagementManager,
        projectName,
        startDate,
        engagementModel,
        scrumMaster,
        practicePartner,
        projectLinks,
        updatedBy,
        updatedOn
    } = data;
    return {
        activeProjectSwitch: active ?? false,
        businessId: businessId ?? '',
        clientName,
        commentary: commentary ?? '',
        engagementManager: engagementManager ?? {},
         endDate,
        locations: Array.from(locations ?? [])
            .map(l => l.locationId ?? 0)
            .map(value => String(value)),
        projectId,
        projectName,
        startDate,
        engagementModel: engagementModel ?? '',
        scrumMaster: scrumMaster ?? {},
        practicePartner: practicePartner ?? {},
        projectLinks: projectLinks ?? [],
        updatedBy,
        updatedOn
    };
};

const formToDto = (values: ProjectFormModel, locationObjects: Location[]) => {
    const locations = locationObjects.filter(l =>
        values.locations.includes(String(l.locationId)),
    );
    return {
        active: values.activeProjectSwitch,
        businessId: values.businessId,
        clientName: values.clientName,
        commentary: values.commentary,
        endDate: values.endDate,
        locations: new Set<Location>(locations),
        engagementManager: values.engagementManager,
        projectId: values.projectId,
        projectName: values.projectName,
        startDate: values.startDate,
        engagementModel:
            values.engagementModel === '' ? null : values.engagementModel,
        scrumMaster: values.scrumMaster,
        practicePartner: values.practicePartner,
        projectLinks: values.projectLinks,
    } as Project;
};

export { dtoToForm, formToDto };
