import Stack from '@mui/material/Stack';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tokenService from '../authentication/tokenService';
import {
    getMetadata,
    subscribeToChanges,
    unsubscribeFromChanges,
} from '../services/metadataCache';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';

const Footer = () => {
    const feedbackUrl = new URL(window.location.href).toJSON();
    const mailtoHref = () =>
        encodeURI(`mailto:SentinelFeedback@ALLEGISCLOUD.onmicrosoft.com?
        subject=<ENTER TOPIC OF SENTINEL FEEDBACK HERE>
        &body=\n\n\n==========
Origin: ${feedbackUrl}
APP Build: #${appBuildNumber}
APP Release: #${appReleaseName}
API Build: #${apiBuildNumber}
API Release: #${apiReleaseName}
`);

    const boardLink =
        'https://tasks.office.com/ALLEGISCLOUD.onmicrosoft.com/Home/PlanViews/CMMW7xcv702FpMgiRNZkumQACdQ7?Type=PlanLink&Channel=Link&CreatedTime=638094761826190000';
    const releaseNotesLink = '/ReleaseNotes';
    const { t } = useTranslation();
    const translation = {
        footerAPPBuild: t('footer_app_buildNumber'),
        footerAPPRelease: t('footer_app_releaseName'),
        footerAPIBuild: t('footer_api_buildNumber'),
        footerAPIRelease: t('footer_api_releaseName'),
        footerSendFeedback: t('footer_sendFeedBack_mailTo'),
        footerEditAccess: t('footer_requestEditAccess'),
        footerFeedbackBoard: t('footer_feedbackBoard_boardlink'),
        footerReleaseNotes: t('footer_releaseNotes'),
        requestAccessUrl: t('sentinel_request_access_url'),
    };

    const [apiBuildNumber, setApiBuildNumber] = useState(
        getMetadata<string>('REACT_API_BUILDNUMBER'),
    );
    const [apiReleaseName, setApiReleaseName] = useState(
        getMetadata<string>('REACT_API_RELEASENAME'),
    );

    const appBuildNumber = useMemo(
        () => getMetadata<string>('REACT_APP_BUILDNUMBER'),
        [],
    );
    const appReleaseName = useMemo(
        () => getMetadata<string>('REACT_APP_RELEASENAME'),
        [],
    );

    useEffect(() => {
        subscribeToChanges<string>('REACT_API_BUILDNUMBER', setApiBuildNumber);
        subscribeToChanges('REACT_API_RELEASENAME', setApiReleaseName);

        return () => {
            unsubscribeFromChanges('REACT_API_BUILDNUMBER', setApiBuildNumber);
            unsubscribeFromChanges('REACT_API_RELEASENAME', setApiReleaseName);
        };
    }, []);

    const navigate = useNavigate();

    return (
        <footer>
            <Stack
                direction="row"
                justifyContent={'space-between'}
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    zIndex: 1,
                    width: '100vw',
                    backgroundColor: 'white',
                    border: 'solid 1px #E0E0E0',
                    padding: '0.5em 1.5em 0.5em 0.5em',
                    fontSize: '0.9rem',
                }}
            >
                <Stack direction={'row'} spacing={2}>
                    <div>
                        <Link
                            component="button"
                            onClick={() => navigate(releaseNotesLink)}
                        >
                            {translation.footerReleaseNotes}
                        </Link>
                    </div>
                    <div>
                        {translation.footerAPPBuild} # {appBuildNumber}
                    </div>
                    <div>
                        {translation.footerAPPRelease} # {appReleaseName}
                    </div>

                    <div>&mdash;</div>

                    <div>
                        {translation.footerAPIBuild} # {apiBuildNumber}
                    </div>
                    <div>
                        {translation.footerAPIRelease} # {apiReleaseName}
                    </div>
                </Stack>
                <Stack direction={'row'} alignItems="center" spacing={2}>
                    <div>
                        <Link href={mailtoHref()} rel="noreferrer">
                            {translation.footerSendFeedback}
                        </Link>
                    </div>

                    <div>
                        <Link
                            href={boardLink}
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            {translation.footerFeedbackBoard}
                        </Link>
                    </div>
                    {!tokenService.hasEditingRights() && (
                        <div>
                            <Link
                                href={translation.requestAccessUrl}
                                rel="noreferrer noopener"
                                data-testid="request-edit-footer-link"
                                target={'_blank'}
                            >
                                {translation.footerEditAccess}
                            </Link>
                        </div>
                    )}
                </Stack>
            </Stack>
        </footer>
    );
};

export default Footer;
