// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';
import type { ProjectLink } from './ProjectLink';
import {
    ProjectLinkFromJSON,
    ProjectLinkFromJSONTyped,
    ProjectLinkToJSON,
} from './ProjectLink';
import type { User } from './User';
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from './User';

/**
 *
 * @export
 * @interface Project
 */
export interface Project {
    /**
     *
     * @type {string}
     * @memberof Project
     */
    projectId?: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    projectName: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    businessId?: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    managerName?: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    clientName: string;
    /**
     *
     * @type {Date}
     * @memberof Project
     */
    startDate: Date;
    /**
     *
     * @type {Date}
     * @memberof Project
     */
    endDate: Date;
    /**
     *
     * @type {number}
     * @memberof Project
     */
    sprintAge?: number;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    createdBy?: string;
    /**
     *
     * @type {Date}
     * @memberof Project
     */
    createdOn?: Date;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    updatedBy?: string;
    /**
     *
     * @type {Date}
     * @memberof Project
     */
    updatedOn?: Date;
    /**
     *
     * @type {Set<Location>}
     * @memberof Project
     */
    locations?: Set<Location>;
    /**
     *
     * @type {number}
     * @memberof Project
     */
    overallScore?: number;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    overallScoreGrade?: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    engagementModel?: ProjectEngagementModelEnum;
    /**
     *
     * @type {boolean}
     * @memberof Project
     */
    active?: boolean;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    commentary?: string;
    /**
     *
     * @type {User}
     * @memberof Project
     */
    engagementManager?: User;
    /**
     *
     * @type {User}
     * @memberof Project
     */
    scrumMaster?: User;
    /**
     *
     * @type {User}
     * @memberof Project
     */
    practicePartner?: User;
    /**
     *
     * @type {Array<ProjectLink>}
     * @memberof Project
     */
    projectLinks?: Array<ProjectLink>;
}

/**
 * @export
 */
export const ProjectEngagementModelEnum = {
    Outcome: 'OUTCOME',
    Comanaged: 'COMANAGED',
    Capacity: 'CAPACITY',
    Assessment: 'ASSESSMENT',
} as const;
export type ProjectEngagementModelEnum =
    (typeof ProjectEngagementModelEnum)[keyof typeof ProjectEngagementModelEnum];

/**
 * Check if a given object implements the Project interface.
 */
export function instanceOfProject(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && 'projectName' in value;
    isInstance = isInstance && 'clientName' in value;
    isInstance = isInstance && 'startDate' in value;
    isInstance = isInstance && 'endDate' in value;

    return isInstance;
}

export function ProjectFromJSON(json: any): Project {
    return ProjectFromJSONTyped(json, false);
}

export function ProjectFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): Project {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        projectId: !exists(json, 'projectId') ? undefined : json['projectId'],
        projectName: json['projectName'],
        businessId: !exists(json, 'businessId')
            ? undefined
            : json['businessId'],
        managerName: !exists(json, 'managerName')
            ? undefined
            : json['managerName'],
        clientName: json['clientName'],
        startDate: new Date(`${json['startDate']}T12:00`),
        endDate: new Date(`${json['endDate']}T12:00`),
        sprintAge: !exists(json, 'sprintAge') ? undefined : json['sprintAge'],
        createdBy: !exists(json, 'createdBy') ? undefined : json['createdBy'],
        createdOn: !exists(json, 'createdOn')
            ? undefined
            : new Date(json['createdOn']),
        updatedBy: !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        updatedOn: !exists(json, 'updatedOn')
            ? undefined
            : new Date(json['updatedOn']),
        locations: !exists(json, 'locations')
            ? undefined
            : new Set((json['locations'] as Array<any>).map(LocationFromJSON)),
        overallScore: !exists(json, 'overallScore')
            ? undefined
            : json['overallScore'],
        overallScoreGrade: !exists(json, 'overallScoreGrade')
            ? undefined
            : json['overallScoreGrade'],
        engagementModel: !exists(json, 'engagementModel')
            ? undefined
            : json['engagementModel'],
        active: !exists(json, 'active') ? undefined : json['active'],
        commentary: !exists(json, 'commentary')
            ? undefined
            : json['commentary'],
        engagementManager: !exists(json, 'engagementManager')
            ? undefined
            : UserFromJSON(json['engagementManager']),
        scrumMaster: !exists(json, 'scrumMaster')
            ? undefined
            : UserFromJSON(json['scrumMaster']),
        practicePartner: !exists(json, 'practicePartner')
            ? undefined
            : UserFromJSON(json['practicePartner']),
        projectLinks: !exists(json, 'projectLinks')
            ? undefined
            : (json['projectLinks'] as Array<any>).map(ProjectLinkFromJSON),
    };
}

export function ProjectToJSON(value?: Project | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        projectId: value.projectId,
        projectName: value.projectName,
        businessId: value.businessId,
        managerName: value.managerName,
        clientName: value.clientName,
        startDate: new Date(
            value.startDate.getTime() -
                value.startDate.getTimezoneOffset() * 60000,
        )
            .toISOString()
            .substring(0, 10),
        endDate: new Date(
            value.endDate.getTime() - value.endDate.getTimezoneOffset() * 60000,
        )
            .toISOString()
            .substring(0, 10),
        sprintAge: value.sprintAge,
        createdBy: value.createdBy,
        createdOn:
            value.createdOn === undefined
                ? undefined
                : value.createdOn.toISOString(),
        updatedBy: value.updatedBy,
        updatedOn:
            value.updatedOn === undefined
                ? undefined
                : value.updatedOn.toISOString(),
        locations:
            value.locations === undefined
                ? undefined
                : Array.from(value.locations as Set<any>).map(LocationToJSON),
        overallScore: value.overallScore,
        overallScoreGrade: value.overallScoreGrade,
        engagementModel: value.engagementModel,
        active: value.active,
        commentary: value.commentary,
        engagementManager: UserToJSON(value.engagementManager),
        scrumMaster: UserToJSON(value.scrumMaster),
        practicePartner: UserToJSON(value.practicePartner),
        projectLinks:
            value.projectLinks === undefined
                ? undefined
                : (value.projectLinks as Array<any>).map(ProjectLinkToJSON),
    };
}
