import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { Sprint } from '../../api/sentinel';
import tokenService from '../../authentication/tokenService';
import ReadOnlyTooltip from '../../common/ReadOnlyTooltip';
import Sprints from './Sprints';
import SprintGoal from './SprintGoal';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

export default function NavigationPanel({
    selectedSprint,
    sprints,
    projectId,
    iterationGoalSucceeded,
}: {
    selectedSprint?: Sprint;
    sprints?: Sprint[];
    projectId: string;
    iterationGoalSucceeded?: 'Y' | 'N';
}) {
    const { t } = useTranslation();
    const translation = {
        btnText: t('navigationPanel_addSprintBtn'),
        sprintCommentary: t('navigationPanel_sprintCommentary'),
    };

    const linkToAdd = () => {
        const latestSprintId = sprints?.length
            ? sprints?.reduce(
                  (max, sprint) => Math.max(max, sprint.sprintId ?? 0),
                  0,
              )
            : '';

        return `/AddSprint/${projectId}/${latestSprintId}`;
    };

    const truncatedStyle = {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '2',
    };
    const [isTruncated, setIsTruncated] = useState(true);

    const toogleTruncation = () => {
        setIsTruncated(!isTruncated);
    };

    return (
        <>
            <Box
                sx={{
                    borderBottom: 'solid 1px #dadada',
                    borderTop: 'solid 1px #dadada',
                    backgroundColor: 'white',
                    margin: '0 0 1em 0',
                }}
            >
                <Container
                    maxWidth={false}
                    disableGutters
                    sx={{ padding: '0 3%' }}
                >
                    <Grid container spacing={0}>
                        <Grid
                            item
                            md={6}
                            sm={12}
                            sx={{ borderRight: 'solid 1px #dadada' }}
                            padding={'1em 1em 1em 0'}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="baseline"
                                spacing={0}
                            >
                                <Sprints
                                    projectId={projectId}
                                    sprints={sprints ?? []}
                                    sprintId={selectedSprint?.sprintId}
                                />
                                <ReadOnlyTooltip
                                    childDisabled={
                                        !tokenService.hasEditingRights()
                                    }
                                >
                                    <Button
                                        href={linkToAdd()}
                                        startIcon={<AddIcon />}
                                        size="small"
                                        color="primary"
                                        disabled={
                                            !tokenService.hasEditingRights()
                                        }
                                        data-testid="addSprintButton"
                                    >
                                        {translation.btnText}
                                    </Button>
                                </ReadOnlyTooltip>
                            </Stack>
                        </Grid>
                        <Grid item sm={12} md={6} padding={'1em 0em 1em 1em'}>
                            <SprintGoal
                                message={selectedSprint?.sprintGoal}
                                status={iterationGoalSucceeded}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {selectedSprint?.commentary && (
                <Box
                    sx={{
                        backgroundColor: 'white',
                        borderBottom: 'solid 1px #dadada',
                        marginTop: '-1em',
                        padding: '12px 3%',
                    }}
                >
                    <Container maxWidth={false} disableGutters>
                        <Typography
                            component="p"
                            variant="caption"
                            sx={
                                [
                                    { whiteSpace: 'pre-line', cursor: 'pointer' },
                                    isTruncated ? truncatedStyle : { },
                                ]
                            }
                            mb="0"
                            ml="10px"
                            onClick={toogleTruncation}
                        >
                            <b>{translation.sprintCommentary}</b>
                            {selectedSprint?.commentary}
                        </Typography>
                    </Container>
                </Box>
            )}
        </>
    );
}
