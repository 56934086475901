import theme from './theme/theme';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const RequestAccess = () => {
    const { t } = useTranslation();
    const requestAccessUrl = t('sentinel_request_access_url');

    return (
        <Container
            maxWidth={'xl'}
            sx={{
                backgroundColor: theme.palette.common.white,
                padding: '2rem 0',
            }}
        >
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
            >
                <Typography
                    variant="h2"
                    color="primary"
                    component={'div'}
                    sx={{ textTransform: 'none' }}
                >
                    <div>You do not have access to Sentinel.</div>
                </Typography>
                <Typography
                    variant="h6"
                    color="primary"
                    component={'div'}
                    sx={{ textTransform: 'none' }}
                >
                    <div>
                        <a
                            href={requestAccessUrl}
                            rel="noreferrer noopener"
                            data-testid="request-edit-footer-link"
                            target="_blank"
                        >
                            Please click here for instructions on how to request
                            access to Sentinel.
                        </a>
                    </div>
                </Typography>
            </Stack>
        </Container>
    );
};

export default RequestAccess;
