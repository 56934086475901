import {useNavigate, useParams} from "react-router-dom";
import {
    DataGrid,
    GridColDef,
} from '@mui/x-data-grid';
import Spinner from "../common/Spinner";
import useProjectRevisionsData from "./useProjectRevisionsData";
import {t} from "i18next";
import {Project} from "../api/sentinel";
import {Container, IconButton, Box, List, ListItem} from "@mui/material";
import theme from "../theme/theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dateService from "../services/dateService";
import WidgetHeader from "../dashboard/common/widget/WidgetHeader";
import Navbar from "./navBar/NavBar";

const ProjectRevisions = () => {
    const previousPage = -1;
    const navigate = useNavigate();
    const handleBackButtonClick = () => {
        navigate(previousPage);
    };
    const {projectId} = useParams();
    const {
        isLoading,
        error,
        data: revisionsArray,
    } = useProjectRevisionsData(projectId);

    if (isLoading) {
        return <Spinner open={true}/>;
    }
    if (error) {
        return <>Error: {error}</>;
    }

    const revisionsList = revisionsArray?.projectRevisions;

    const projectName = revisionsList ? revisionsList[0]?.projectName : '';
    const commonAttributes = {
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
    };

    const field = (
        fieldName: keyof Project,
        options?: Partial<GridColDef>,
    ): GridColDef => {
        return {
            field: fieldName,
            headerName: t(`projectRevisions_${fieldName}`),
            ...commonAttributes,
            ...options,
        };
    };

    const projectColumns: GridColDef[] = [
        field("updatedOn", {
            type: 'date',
            valueFormatter: ({value}) =>
                dateService.toStandardDateTimeFormat(value),
            flex: 0.5,
            minWidth: 150,
        }),
        field("updatedBy", {
            flex: 1,
            minWidth: 200,
        }),
        field("clientName", {
            flex: 1,
            minWidth: 200,
        }),
        field("commentary", {
            flex: 1,
            minWidth: 200,
        }),
        field("projectName", {
            flex: 1,
            minWidth: 200,
        }),
        field("businessId", {
            flex: 1,
            minWidth: 200,
        }),
        field("engagementManager", {
            flex: 1.5,
            minWidth: 280,
            valueGetter: ({row}) => row.engagementManager?.upn,
        }),
        field("practicePartner", {
            flex: 1.5,
            minWidth: 280,
            valueGetter: ({row}) => row.practicePartner?.upn,
        }),
        field("scrumMaster", {
            flex: 1.5,
            minWidth: 280,
            valueGetter: ({row}) => row.scrumMaster?.upn,
        }),
        field("startDate", {
            flex: 0.5,
            minWidth: 150,
            type: 'date',
            valueFormatter: ({value}) =>
                dateService.toStandardDateFormat(value),
        }),
        field("endDate", {
            flex:  0.5,
            minWidth: 150,
            type: 'date',
            valueFormatter: ({value}) =>
                dateService.toStandardDateFormat(value),
        }),
        field("active", {
            flex:0.5,
            minWidth: 150,
            valueGetter: ({row}) => row.active ? `${t('projectRevisions_active_yes')}` : `${t('projectRevisions_active_no')}`,
        }),
        field("locations", {
            flex: 1.5,
            minWidth: 280,
            valueGetter: ({row}) => row.locations,
            renderCell: ({row}) => {
                const locationsSet = row.locations;
                const locationsArray = Array.from(locationsSet).map(location => (location as {
                    location: string;
                }).location);
                return (
                    <List>
                        {locationsArray.map((loc) => (
                            <ListItem disableGutters key={loc}>
                                {loc}
                            </ListItem>
                        ))}
                    </List>
                )
            },
        }),
        field("engagementModel", {
            flex: 0.5,
            minWidth: 150,
            valueGetter: ({row}) => row?.engagementModel
        }),
        field("projectLinks", {
            flex: 1.5,
            minWidth: 280,
            valueGetter: ({row}) => row.projectLinks,
            renderCell: ({row}) => {
                const projectLinksArray = row.projectLinks;
                return (
                    <List>
                        {projectLinksArray.map((projLink: { url: string | null | undefined; }) => (
                            <ListItem disableGutters key={projLink.url}>
                                {projLink.url}
                            </ListItem>
                        ))}
                    </List>
                )
            },
        }),
    ];

    return (
        <>
            {!revisionsList?.length ? (
                    <Container
                        maxWidth={false}
                        disableGutters
                        sx={{
                            padding: '1rem 3%',
                            backgroundColor: theme.palette.common.white,
                            borderBottom: `solid 1px ${theme.palette.grey["300"]}`
                        }}
                    >
                        <IconButton
                            aria-label={'back_button'}
                            data-testid="back-button-empty-revision"
                            onClick={() => handleBackButtonClick()}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        {t('projectRevisions_noRevisions')}
                    </Container>
            ) : (
                <Container maxWidth={false}>
                    <Box sx={{padding: '1rem', backgroundColor: theme.palette.common.white, borderRadius: '4px', borderTopLeftRadius: '20px'}}>
                        <WidgetHeader
                            gridArea={'H'}
                            title={t('projectRevisions_title', {projectName})}
                            subtitle={''}
                            trend={undefined}
                        />
                        <Navbar
                            projectName={projectName}
                            projectId={projectId}
                        />

                        <Box sx={{height: 'auto', maxHeight: '70vh'}}>
                            <DataGrid
                                getRowHeight={() => 'auto'}
                                columns={projectColumns}
                                density={'standard'}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'updatedOn', sort: 'desc' }],
                                    },
                                    pagination: { paginationModel: { pageSize: 10 } }
                                }}
                                rows={revisionsList}
                                pageSizeOptions={[10]}
                                disableDensitySelector
                                disableVirtualization
                                disableRowSelectionOnClick
                                getRowId={(revision: Project) => revision.updatedOn?.getTime() ?? ''}
                                sx={{
                                    '& .MuiDataGrid-row': {
                                        padding: '8px 0',
                                    },
                                }}
                            />
                        </Box>

                    </Box>
                </Container>
            )}
        </>
    );
};
export default ProjectRevisions;
